import {ADDRESS_KEY, PROJECT_NAME, USER_ADDRESS} from "../constants";
import {connex} from "../constants/contract";
import bulliesGif from './../images/ezgif-5-282ef025ff.gif';

const Home = () => {
    const handleLogIn = async () => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/";
            })
    };

    import('./../Home.css')
    return <div>
        <section className="u-clearfix home-u-section-1" id="sec-4c37">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
              <h1 className={"u-align-center-md u-align-center-sm u-align-center-xs u-text-default"}>BULLYS MINT IS NOW LIVE : CONNECT WALLET TO SEE MENU</h1>< br />
              {!USER_ADDRESS ? <div><a onClick={handleLogIn} className="u-btn u-button-style u-btn-1">Connect wallet</a><br /></div> : <div><a href="/school-yard" className="u-btn u-button-style u-btn-1">MINT A BULLY</a><br /></div>  }
              <div className="u-layout-row">
                <div className="u-align-center u-container-style u-layout-cell u-left-cell u-size-23 u-layout-cell-1">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                    <img src={bulliesGif} />
                    <div alt="" className="u-image u-image-circle u-image-1" src="" data-image-width="1131" data-image-height="1600"></div>
                </div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-37 u-layout-cell-2">
                <div className="u-container-layout u-container-layout-2">
                  <h2 className="u-align-center u-custom-font u-text u-text-1">What do we stand for at No Nerds Inc. ?</h2>
                  <p className="u-align-center u-text u-text-2">
                      - ​No BS roadmap.<br/>
                      - No unfounded hype.<br/>
                      - No off-the-shelf copycat game.<br/>
                      - No overly complex rarity system.<br/>
                      - No need to remortgage your house to buy one.<br/>
                      - Most Important No nerds.
                  </p>
                    {USER_ADDRESS ? <a href="/school-yard" className="u-btn u-button-style u-btn-1">Get a Bully</a> : null}
                    {USER_ADDRESS ? <p className="u-align-center u-text u-text-2"> or </p> : null}
                    {USER_ADDRESS ? <a href="https://blackvemarket.com/explore/all/Project/0x77FE6041fA5beb0172C9aB6014b4D8d5099F0A23" className="u-btn u-button-style u-btn-1">Prevent nerdness</a> : null}
                    {!USER_ADDRESS ? <a onClick={handleLogIn} className="u-btn u-button-style u-btn-1">Connect wallet</a> : null }
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-gradient home-u-section-2" id="sec-3d2c">
      <div className="u-clearfix u-sheet u-sheet-1">
        <h1 className="u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-default u-title u-text-1">No Nerds Incorporated</h1>
        <h2 className="u-custom-font u-subtitle u-text u-text-default u-text-2">Important information.</h2>
        <div className="u-align-left u-border-10 u-border-grey-80 u-container-style u-group u-radius-22 u-shape-round u-group-1">
          <div className="u-container-layout u-container-layout-1">
              <span className="u-text u-text-3">
            <p>No Nerds Inc is a collaboration project, managed amongst several longtime VeChain holders, that sets out to reward the most loyal VeFam members while having a bit of fun along the way. No Nerds Inc created their first NFT project with Anti-N.E.R.D Tablets; originally conceived as a running joke amongst the founding members, “Nerds” (or N.E.R.D’s – short for “Negatively Enforced Retail Dealers”) are responsible for negative selling pressure and were in need of special Anti-N.E.R.D medicine to get them feeling bullish once again.</p>
            <p>Although Anti-N.E.R.D Tablets started as a joke, No Nerds Inc quickly turned them into a very real NFT centered around a few core principles – affordability, simplicity, and rewards. The tablets were intended to be affordable for VeFam at any stage of their crypto journey, while also rewarding holders through a variety of methods depending on the amount of NFTs held, which include a share of aftermarket sales, airdrops of a community-based token ($UNION), and whitelists for future No Nerds Inc projects.</p>
            <p>Meanwhile, the project looked to strike a balance between a great design and rarity system that was interesting enough for the experienced NFT buyer, yet simple enough to be enjoyed by newer NFT enthusiasts as well. Future projects from No Nerds Inc include the “Union Membership NFT” and “Bullys” art collection. The Union Membership NFT will increase rewards for holders with exclusive whitelists, revenue sharing from Union activities, and several additional ongoing perks.</p>
            <p>The Bullys collection will be No Nerds Inc’s premier NFT art collection. This series will continue much of the “Anti-Nerd” fun, as witnessed with the Anti-N.E.R.D tablet series, but with elevated artwork and more intricate attributes. Bullys will additionally continue to grant holders a share of aftermarket sales, as seen in previous No Nerds Inc projects, but this time at an even greater rate. Don’t forget, NFTs in the No Nerd Inc projects accumulate daily $UNION tokens per NFT held, so make sure to start collecting early and protect your keys with Inheriti to ensure access is never lost!</p>
              </span>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-gradient home-u-section-3" id="sec-c6e7">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-22 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-center u-container-style u-layout-cell u-left-cell u-palette-5-light-1 u-radius-22 u-shape-round u-size-30 u-size-xs-60 u-layout-cell-1" src="" data-animation-name="slideIn" data-animation-duration="2750" data-animation-delay="0" data-animation-direction="Left">
                <div className="u-border-10 u-border-grey-75 u-container-layout u-valign-bottom u-container-layout-1"><span className="u-icon u-icon-1"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 60 60"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-71ea"></use></svg><svg className="u-svg-content" viewBox="0 0 60 60" x="0px" y="0px" id="svg-71ea" style={{enableBackground: 'new 0 0 60 60'}}><path d="M55.014,45.389l-9.553-4.776C44.56,40.162,44,39.256,44,38.248v-3.381c0.229-0.28,0.47-0.599,0.719-0.951
	c1.239-1.75,2.232-3.698,2.954-5.799C49.084,27.47,50,26.075,50,24.5v-4c0-0.963-0.36-1.896-1-2.625v-5.319
	c0.056-0.55,0.276-3.824-2.092-6.525C44.854,3.688,41.521,2.5,37,2.5s-7.854,1.188-9.908,3.53c-1.435,1.637-1.918,3.481-2.064,4.805
	C23.314,9.949,21.294,9.5,19,9.5c-10.389,0-10.994,8.855-11,9v4.579c-0.648,0.706-1,1.521-1,2.33v3.454
	c0,1.079,0.483,2.085,1.311,2.765c0.825,3.11,2.854,5.46,3.644,6.285v2.743c0,0.787-0.428,1.509-1.171,1.915l-6.653,4.173
	C1.583,48.134,0,50.801,0,53.703V57.5h14h2h44v-4.043C60,50.019,58.089,46.927,55.014,45.389z M14,53.262V55.5H2v-1.797
	c0-2.17,1.184-4.164,3.141-5.233l6.652-4.173c1.333-0.727,2.161-2.121,2.161-3.641v-3.591l-0.318-0.297
	c-0.026-0.024-2.683-2.534-3.468-5.955l-0.091-0.396l-0.342-0.22C9.275,29.899,9,29.4,9,28.863v-3.454
	c0-0.36,0.245-0.788,0.671-1.174L10,23.938l-0.002-5.38C10.016,18.271,10.537,11.5,19,11.5c2.393,0,4.408,0.553,6,1.644v4.731
	c-0.64,0.729-1,1.662-1,2.625v4c0,0.304,0.035,0.603,0.101,0.893c0.027,0.116,0.081,0.222,0.118,0.334
	c0.055,0.168,0.099,0.341,0.176,0.5c0.001,0.002,0.002,0.003,0.003,0.005c0.256,0.528,0.629,1,1.099,1.377
	c0.005,0.019,0.011,0.036,0.016,0.054c0.06,0.229,0.123,0.457,0.191,0.68l0.081,0.261c0.014,0.046,0.031,0.093,0.046,0.139
	c0.035,0.108,0.069,0.215,0.105,0.321c0.06,0.175,0.123,0.356,0.196,0.553c0.031,0.082,0.065,0.156,0.097,0.237
	c0.082,0.209,0.164,0.411,0.25,0.611c0.021,0.048,0.039,0.1,0.06,0.147l0.056,0.126c0.026,0.058,0.053,0.11,0.079,0.167
	c0.098,0.214,0.194,0.421,0.294,0.621c0.016,0.032,0.031,0.067,0.047,0.099c0.063,0.125,0.126,0.243,0.189,0.363
	c0.108,0.206,0.214,0.4,0.32,0.588c0.052,0.092,0.103,0.182,0.154,0.269c0.144,0.246,0.281,0.472,0.414,0.682
	c0.029,0.045,0.057,0.092,0.085,0.135c0.242,0.375,0.452,0.679,0.626,0.916c0.046,0.063,0.086,0.117,0.125,0.17
	c0.022,0.029,0.052,0.071,0.071,0.097v3.309c0,0.968-0.528,1.856-1.377,2.32l-2.646,1.443l-0.461-0.041l-0.188,0.395l-5.626,3.069
	C15.801,46.924,14,49.958,14,53.262z M58,55.5H16v-2.238c0-2.571,1.402-4.934,3.659-6.164l8.921-4.866
	C30.073,41.417,31,39.854,31,38.155v-4.018v-0.001l-0.194-0.232l-0.038-0.045c-0.002-0.003-0.064-0.078-0.165-0.21
	c-0.006-0.008-0.012-0.016-0.019-0.024c-0.053-0.069-0.115-0.152-0.186-0.251c-0.001-0.002-0.002-0.003-0.003-0.005
	c-0.149-0.207-0.336-0.476-0.544-0.8c-0.005-0.007-0.009-0.015-0.014-0.022c-0.098-0.153-0.202-0.32-0.308-0.497
	c-0.008-0.013-0.016-0.026-0.024-0.04c-0.226-0.379-0.466-0.808-0.705-1.283c0,0-0.001-0.001-0.001-0.002
	c-0.127-0.255-0.254-0.523-0.378-0.802l0,0c-0.017-0.039-0.035-0.077-0.052-0.116h0c-0.055-0.125-0.11-0.256-0.166-0.391
	c-0.02-0.049-0.04-0.1-0.06-0.15c-0.052-0.131-0.105-0.263-0.161-0.414c-0.102-0.272-0.198-0.556-0.29-0.849l-0.055-0.178
	c-0.006-0.02-0.013-0.04-0.019-0.061c-0.094-0.316-0.184-0.639-0.26-0.971l-0.091-0.396l-0.341-0.22
	C26.346,25.803,26,25.176,26,24.5v-4c0-0.561,0.238-1.084,0.67-1.475L27,18.728V12.5v-0.354l-0.027-0.021
	c-0.034-0.722,0.009-2.935,1.623-4.776C30.253,5.458,33.081,4.5,37,4.5c3.905,0,6.727,0.951,8.386,2.828
	c1.947,2.201,1.625,5.017,1.623,5.041L47,18.728l0.33,0.298C47.762,19.416,48,19.939,48,20.5v4c0,0.873-0.572,1.637-1.422,1.899
	l-0.498,0.153l-0.16,0.495c-0.669,2.081-1.622,4.003-2.834,5.713c-0.297,0.421-0.586,0.794-0.837,1.079L42,34.123v4.125
	c0,1.77,0.983,3.361,2.566,4.153l9.553,4.776C56.513,48.374,58,50.78,58,53.457V55.5z"></path></svg>


              </span>
                  <h2 className="u-custom-font u-text u-text-1">Ceo of NoNerdsInc.</h2>
                  <p className="u-text u-text-2"> Chad M "No Nerds" Schmichael Jr</p>
                  <h2 className="u-custom-font u-text u-text-3">Board of directors</h2>
                  <p className="u-small-text u-text u-text-variant u-text-4">
                      Union Executive - <a href="https://twitter.com/vechain4lambo" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1" target="_blank">@Vechain4lambo</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/SillyLittleCoin" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2" target="_blank">@Prince of Turkey</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/El_l1ex" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-3" target="_blank">@Ell1ex</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/BuccNasti" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-4" target="_blank">@Dr Buc Nasti</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/elec_enginerd" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-5" target="_blank">@EE</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/mcvetpants" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-6" target="_blank">@McVetPants</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/vechainey" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-7" target="_blank">@Dick VeChainey</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/CryptoLloyd" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-9" target="_blank">@CryptoLloyd</a>
                    <br/>
                    <br/>Union Executive - <a href="https://twitter.com/fvckb0b" rel="noreferrer" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-10" target="_blank">@fvckb0b</a>
                  </p>
                </div>
              </div>
              <div className="u-align-center u-container-style u-layout-cell u-palette-5-light-1 u-radius-22 u-right-cell u-shape-round u-size-30 u-size-xs-60 u-layout-cell-2" src="" data-animation-name="slideIn" data-animation-duration="2750" data-animation-delay="0" data-animation-direction="Right">
                <div className="u-border-10 u-border-grey-75 u-container-layout u-container-layout-2">
                  <div className="u-border-20 u-border-custom-color-2 u-image u-image-circle u-image-1" data-image-width="2048" data-image-height="2048"></div>
                  <h3 className="u-custom-font u-text u-text-5">Super Safe to use !<br/>
                  </h3>
                  <p className="u-text u-text-6">One of the advantages is you can't overdose on ANTI-N.E.R.D Tablets.&nbsp;<br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-align-left u-clearfix u-image u-typography-custom-page-typography-8--Introduction home-u-section-4" src="" id="sec-6616" data-image-width="1500" data-image-height="500">
      <div className="u-clearfix u-sheet u-sheet-1">
        <h1 className="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xl u-text-1">Get your me​dicine today</h1>
        <div className="u-border-4 u-border-palette-5-dark-2 u-line u-line-horizontal u-line-1"></div>
        <h3 className="u-text u-text-default u-text-2">100 $VET each.</h3>
        <p className="u-large-text u-text u-text-default-lg u-text-default-md u-text-default-xl u-text-variant u-text-3"> Future benefits for full pack holders of tablets (12)</p>
        <a href="/Pharmacy" data-page-id="9963128" className="u-border-none u-btn u-btn-round u-button-style u-custom u-palette-5-dark-2 u-radius-24 u-btn-1">I want them</a>
      </div>
    </section>
    </div>
};

export default Home;
