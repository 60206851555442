import {findMethodABI, getReceipt, sleep} from "../../utilities/util";
import {toast} from "react-toastify";
import {connex} from "../../constants/contract";

const transferTokens = async (ABI, contract, recipient, user, amountToSend) => {
    const balanceOfABI = findMethodABI(ABI, 'balanceOf');
    try {
        //check if recipient address is valid
        await contract.method(balanceOfABI).call(recipient);
    } catch {
        toast.error("Address is incorrect!", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
    }
    let amount = ((amountToSend) * ("1e" + 18)).toString()
    const transferMethod = findMethodABI(ABI, 'transfer')
    const transfer = contract.method(transferMethod).caller(user)

    transfer.call(recipient, amount)
        .then(resp => {
            if (!!resp.vmError) {
                toast.error(resp.revertReason, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });

                return false;
            }
        }).catch(err => {
            toast.error("there was an error: " + err, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            });
    })

    const resp = await connex.vendor.sign(
        'tx',
        [transfer.asClause(recipient, amount)])
        .comment('transfer UNION')
        .request()

    let receipt = null;
    while (receipt == null) {
        receipt = await getReceipt(resp.txid)
        await sleep(1000);
    }
}

export default transferTokens;
