import {useEffect, useState} from "react";
import {USER_ADDRESS} from "../constants";
import {bullies_contract} from "../constants/contract";
import {BulliesAbi} from "../constants/BulliesAbi";
import getDataFromNftraritytool from "../components/functions/getDataFromNftraritytool"
import transferNft from "../components/functions/transferNft";
import {findMethodABI} from "../utilities/util";

const MyCrew = () => {
    const [countBulliesInWallet, setCountBulliesInWallet] = useState(0)
    const [metadata, setMetadata] = useState([])
    const [recipient, setRecipient] = useState('')

    useEffect(async () => {
        if (USER_ADDRESS) {
            await currentBullies();
        }
    }, [])

    const currentBullies = async () => {
        let currentInWallet = await (bullies_contract.method(findMethodABI(BulliesAbi, 'walletOfOwner')).call(USER_ADDRESS));
        currentInWallet = currentInWallet.decoded[0].sort(function (a, b) {
            return a - b
        });
        setCountBulliesInWallet(currentInWallet.length)
        let metas = await getDataFromNftraritytool(currentInWallet, 'bullys')

        setMetadata(metas)
    }

    const transfer = async (index) => {
        await transferNft(BulliesAbi, bullies_contract, recipient, metadata[index].result.edition)
        await currentBullies();
    }

    import('../My-Crew.css')

    return <div>
        <section className="u-clearfix u-palette-5-light-3 my-crew-u-section-1" id="sec-7727">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-text u-text-default u-text-1">Your Crew</h1>
                <h3 className="u-text u-text-default u-text-2">Bullys in your crew: {countBulliesInWallet}</h3>
                <hr/>

                {metadata.map((token, index) =>
                    <div key={index}>
                        <img className="u-image u-image-default u-preserve-proportions u-image-1"
                             src={token.img} alt="" data-image-width="3000"
                             data-image-height="3000"/>
                        <table width={'100%'}>
                            <thead>
                            <tr><td colSpan={2} style={{textAlign: 'center'}}><h4 className="u-text u-text-default u-text-3">Attributes:</h4></td></tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td align={"right"}>id: </td>
                                <td style={{paddingLeft: '8px'}}>{token.result.edition}</td>
                            </tr>
                            {token.result.attributes.map((attribute) =>
                                <tr key={index + "-" + attribute.trait_type} >
                                    <td align={"right"}>{attribute.trait_type}: </td>
                                    <td style={{paddingLeft: '8px'}}>{attribute.value}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <h4 className="u-text u-text-default u-text-3">Transfer your Bully to a Nerd.</h4>
                        <div className="u-form-group u-form-name">
                            <input type="text" placeholder="Only Transfer to a VeChainThor Address"
                                   name="Transfer"
                                   className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white"
                                   onChange={e => setRecipient(e.target.value)}/>
                            <button className="u-btn u-btn-submit u-button-style" onClick={() => transfer(index)}>Send now</button>
                       </div>
                        <hr/>
                    </div>
                )}

            </div>
        </section>

    </div>;
}

export default MyCrew;
