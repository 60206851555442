import dhrteshdex from './../images/dhrteshdex.png';
import {useEffect, useState} from "react";
import {connex, no_nerds_contract,union_token_contract} from "../constants/contract"
import {nonerdsAbi} from "../constants/nonerdsAbi";
import {unionTokenAbi} from "../constants/unionTokenAbi";
import {USER_ADDRESS} from "../constants";
import {toast} from "react-toastify";
import {findMethodABI, getReceipt, sleep} from "../utilities/util";
import transferTokens from "../components/functions/transferTokens";
const Uniontoken = () => {

    const [balance, setBalance] = useState(0)
    const [vault, setVault] = useState(0)
    const [historicalVault, setHistoricalVault] = useState(0)
    const [tokensInAirdrop, setTokensInAirdrop] = useState([])
    const [loading, setLoading] = useState(true)
    const [sendAmount, setSendAmount] = useState(1);
    const [recipient, setRecipient] = useState('0x')
    const [listed, setListed] = useState([])
    const [unlisted, setUnlisted] = useState([])
    const findMethodABI = (ABI, method) => ABI[ABI.findIndex(mthd => mthd.name === method)]

    const currentCoins = async () => {
        setLoading(true)
        const tokensInAirdropABI = findMethodABI(unionTokenAbi, 'getAllTokensInCurrentAirdrop')
        await (union_token_contract.method(tokensInAirdropABI).call())
            .then((result) => {
                setTokensInAirdrop([...result.decoded[0]])
            })
        let metas = []

        const historicVault = await (union_token_contract.method(findMethodABI(unionTokenAbi, 'tokenInHistoricalVault')).call(USER_ADDRESS))
        const currentVault = await (union_token_contract.method(findMethodABI(unionTokenAbi, 'checkInVaultOwner')).caller(USER_ADDRESS).call())
        const CurrentBalance = await (union_token_contract.method(findMethodABI(unionTokenAbi, 'balanceOf')).call(USER_ADDRESS))
        const currentInAirdrop = await (union_token_contract.method(findMethodABI(unionTokenAbi, 'getAllTokensInCurrentAirdrop')).call());
        const currentInWallet = await (no_nerds_contract.method(findMethodABI(nonerdsAbi, 'walletOfOwner')).call(USER_ADDRESS));

        let unlisted = currentInWallet.decoded[0].filter(x => !currentInAirdrop.decoded[0].includes(x));
        let listed = currentInWallet.decoded[0].filter(x => currentInAirdrop.decoded[0].includes(x));

        setBalance(CurrentBalance.decoded[0])
        setVault(currentVault.decoded[0])
        setHistoricalVault(historicVault.decoded[0])
        setListed([...listed]);
        setUnlisted([...unlisted])
        setLoading(false)
    }
    const renderListAllButton = () => {
        return unlisted.length > 0 ? <button className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1" onClick={listAllForAirdrop}>List all NFTS</button> :
            <span className="u-btn u-btn-round u-button-style u-palette-1-base u-radius-50 u-btn-1" style={{ backgroundColor: '#B0B0B0' }}>All listed</span>;
    }
    const renderClaimButton = () => {
        return <button className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={claim}>Claim UNION</button>
    }
    const claim = async () => {
        let clauses = [];
        if (vault > 0) {
            const vaultMethod = await union_token_contract.method(findMethodABI(unionTokenAbi, 'withdrawFromTokensOwner'))
                .caller(USER_ADDRESS)
            clauses.push(vaultMethod.value(0).asClause())
        }
        if (historicalVault > 0) {
            const claimMethod = await union_token_contract.method(findMethodABI(unionTokenAbi, 'withdrawFromHistoricalVault'))
                .caller(USER_ADDRESS)
            clauses.push(claimMethod.value(0).asClause())
        }

        const request = connex
            .vendor
            .sign('tx', clauses)
            .signer(USER_ADDRESS)
            .comment('Claim vault tokens')
            .accepted(() => console.log('accepted'))

        await setTimeout(() => request.request(), 300)
    }

    const transferUnion = async () => {

        await transferTokens(unionTokenAbi, union_token_contract, recipient, USER_ADDRESS, sendAmount)
        window.location.reload()
    }

    const listAllForAirdrop = async () => {
        const clauses = [];
        const appendMethod = union_token_contract.method(findMethodABI(unionTokenAbi, 'appendCurrentTokenAsOwner'))
            .caller(USER_ADDRESS);
        unlisted.map((token, index) => {
            if (index <= 30) {
                console.log(index)
                if (!tokensInAirdrop.includes(token.toString())) {
                    const clause = appendMethod.value(0).asClause(token)
                    clauses.push(clause);
                }
            }
            return 0;
        })
        connex.vendor.sign(
            'tx',
            clauses
        )
            .comment('List all tokens for airdrop')
            .accepted(() => console.log('accepted'))
            .request()
            .then(result =>
                document.location.href = "/"
            ).catch(err => {
            console.log(err.toString());
        })
    }

    useEffect(async () => {
        if (USER_ADDRESS) {
            await currentCoins();
        }
        setLoading(false);
    }, [])

    import('../uniontoken.css')
    return <section className="u-clearfix u-grey-5 uniontoken-u-section-1" id="sec-d746">
        <div className="u-clearfix u-sheet u-sheet-1">
            <h2 className="u-align-center u-custom-font u-text u-text-default u-text-1">$UNION Banking System</h2>
            <p className="u-align-center u-text u-text-default u-text-2">Your Current Balance: {Number(balance) / ("1e" + 18)}</p>
            <p className="u-align-center u-text u-text-default u-text-3">Not claimed yet: {Number(vault) / ("1e" + 18) + Number(historicalVault) / ("1e" + 18)}</p>
            <p className="u-align-center u-text u-text-default u-text-4">Total NoNerd Tablets in your possesion: {listed.length}</p>
            <p className="u-align-center u-text u-text-default u-text-5">Unlisted NoNerd Tablets: {unlisted.length}</p>
            {renderListAllButton()}
            {renderClaimButton()}
            <br />
            <h2 className={"u-align-center u-custom-font u-text- u-text-default u-text-1"}>Send <strike>Nudes</strike> $UNION</h2>
            <input type="number" placeholder="Amount" style={{width: '25%', display: 'inline-block'}} onChange={num=>setSendAmount(Number(num.target.value))}
                   name="amount" className="u-border-1 u-border-grey-30 u-input" max={Number(balance) / ("1e" + 18)} min={1} />
            <input type="text" placeholder="Public Wallet Address" style={{width: '75%', display: 'inline-block'}} onChange={address=>setRecipient(address.target.value)}
                   name="Address" className="u-border-1 u-border-grey-30 u-input" />
            <button className="u-btn u-btn-round u-button-style u-palette-1-base u-radius-50 u-btn-1" type="button" onClick={() => transferUnion()}>Send</button>
            <hr />
            <img className="u-expanded-width-xs u-image u-image-default u-image-1" src={dhrteshdex} alt=""
                 data-image-width="500" data-image-height="750"/>
            <p className="u-align-center u-text u-text-default u-text-6">To generate UNION you have to enlist your
                A.N.T.I. Nerd Tablet. This does not mean you sell your NFTs !<br/>After enlisting your NFT, UNION
                will be generated every 24 HRS. Come back Daily,weekly,monthly or yearly.&nbsp;<br/>Your UNION
                will just wait on you in your piggybank.
            </p>
        </div>
    </section>;
}

export default Uniontoken
