import {useEffect, useState} from "react";
import bulliesGif from './../images/ezgif-5-282ef025ff.gif';
import BigNumber from "bignumber.js";
import {bullies_contract, connex} from "../constants/contract";
import {ADDRESS_KEY, PROJECT_NAME, USER_ADDRESS} from "../constants";
import {toast} from "react-toastify";
import {getReceipt, findMethodABI, sleep} from "../utilities/util";
import {BulliesAbi} from "../constants/BulliesAbi";
import { ProgressBar } from "react-bootstrap";


const SchoolYard = () => {
    const [totalSupply, setTotalSupply] = useState(0);
    const [maxEntries, setMaxEntries] = useState(0);
    const [mintPrice, setMintPrice] = useState(0);
    const [mintAmount, setMintAmount] = useState(1);
    const [period, setPeriod] = useState(0);
    const [loading, setLoading] = useState(true);
    const [mintsLeft, setmintsLeft] = useState(0)

    useEffect(async () => {
        await initialLoad();
    }, [])

    const initialLoad = async () => {
        const totalSupplyABI = findMethodABI(BulliesAbi, 'maxSupply');
        const totalSupplyMethod = await bullies_contract.method(totalSupplyABI).call();
        setMaxEntries(totalSupplyMethod.decoded[0]);

        const totalMintedABI = findMethodABI(BulliesAbi, 'totalSupply');
        const totalMintedMethod = await bullies_contract.method(totalMintedABI).call();
        setTotalSupply(parseInt(totalMintedMethod.decoded[0]));

        const getCurrentPriceABI = findMethodABI(BulliesAbi, 'getCurrentPrice');
        const getCurrentPriceMethod = await bullies_contract.method(getCurrentPriceABI).call();
        setMintPrice(parseInt(getCurrentPriceMethod.decoded[0]));

        const getCurrentPeriodABI = findMethodABI(BulliesAbi, 'getCurrentPeriod');
        const getCurrentPeriodMethod = await bullies_contract.method(getCurrentPeriodABI).call();
        setPeriod(parseInt(getCurrentPeriodMethod.decoded[0]));

        if (parseInt(getCurrentPeriodMethod.decoded[0]) === 0) {
            const getOnWlABI = findMethodABI(BulliesAbi, 'whitelistedPeriod1');
            const getOnWLMethod = await bullies_contract.method(getOnWlABI).call(USER_ADDRESS);
            setmintsLeft(getOnWLMethod.decoded[0]);
        }
        if (parseInt(getCurrentPeriodMethod.decoded[0]) === 1) {
            const getOnWlABI = findMethodABI(BulliesAbi, 'whitelistedPeriod2');
            const getOnWLMethod = await bullies_contract.method(getOnWlABI).call(USER_ADDRESS);
            setmintsLeft(getOnWLMethod.decoded[0]);
        }
        if (parseInt(getCurrentPeriodMethod.decoded[0]) === 2) {
            setmintsLeft(10);
        }

        setLoading(false);
    }

    const handleLogIn = async () => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/school-yard";
            })
    };

    const mint = async () => {
        if (!USER_ADDRESS) {
            toast.error('Connect your wallet!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            });
        }

        const mintAbi = findMethodABI(BulliesAbi, 'mint')
        const mintMethod = bullies_contract.method(mintAbi).caller(USER_ADDRESS)

        const totalPrice = new BigNumber(mintPrice * mintAmount).toFixed()
        const clause = mintMethod.value(totalPrice).asClause(mintAmount)

        const resp = await connex
            .vendor
            .sign('tx', [clause])
            .comment('Transaction signing, Hire a bully')
            .accepted(() => console.log('accepted'))
            .request()
        ;

        let receipt = null;
        while (receipt == null) {
            receipt = await getReceipt(resp.txid);
            await sleep(1000);
        }

        toast.success('Successfully hired a bully!', {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });

        await initialLoad();
        setLoading(false);
    }
    import ('./../School-Yard.css');

    return <div>
        <section className="u-clearfix u-palette-5-light-3 bullies-u-section-1" id="sec-854b">
            <div className="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-xl u-sheet-1">
                <h1 className="u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-font-playfair-display u-text u-text-default-lg u-text-default-xl u-title u-text-1">
                    Welcome to School Yard</h1>
                <h2 className="u-custom-font u-font-playfair-display u-text u-text-default u-text-2">Get a bully to stomp the NERDS</h2>
                <br />
                <ProgressBar now={totalSupply} max={maxEntries} striped />
                <h6 className="u-custom-font u-text u-text-default u-text-3">Bullys still available : {maxEntries - totalSupply}/{maxEntries}</h6>
                <p className="u-align-center u-text u-text-8">Price : { mintPrice ? (mintPrice * 1) / ("1e" + 18) + ' VET' : 'SEWN'}<br/>
                    Max Mints per TX:
                    { period === 0 ? ' 3 Bullys' : null }
                    { period === 1 ? ' 2 Bullys' : null }
                    { period === 2 ? ' 10 Bullys' : null }
                    { isNaN(period) ? ' SEWN ' : null }
                </p>
                <h5 className="u-align-center u-custom-font u-text u-text-9">How many Bullys would you like to mint?</h5>
                <div
                    className="u-border-4 u-border-grey-dark-1 u-container-style u-group u-shape-rectangle u-group-1">
                    <div className="u-container-layout u-valign-middle">
                        { USER_ADDRESS ?
                            <input type="number" placeholder="amount" id="name-558c" name="amount" max={mintsLeft} min={1}
                                   onChange={num=>setMintAmount(Number(num.target.value))}
                                   className="u-border-1 u-border-grey-30 u-input u-input-rectangle" required="" /> :
                            <input type="text" placeholder="disabled" name="amount" disabled={true} className="u-border-1 u-border-grey-30 u-input u-input-rectangle" required=""/>
                        }

                    </div>
                </div>
                {USER_ADDRESS ?
                    <button onClick={mint}
                            className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-6 u-btn-1">
                        MINT
                    </button> :
                    <button onClick={handleLogIn}
                             className="u-btn u-btn-round u-button-style u-radius-6 u-btn-1">
                        CONNECT WALLET
                    </button>
                }
                <img className="u-image u-image-default u-preserve-proportions u-image-1"
                     src={bulliesGif} alt="" data-image-width="1200" data-image-height="1200"/>
                <p className="u-align-center u-text u-text-11">After Minting your Bully. You will be able to find
                    them in the "<a href="/my-crew" data-page-id="1833315966"
                                    className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2">My
                        Crew</a>" Tab or on "<a href="https://BlackVeMarket.com"
                                                className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-3"
                                                target="_blank">BlackV</a>".
                </p>
            </div>
        </section>
    </div>
}

export default SchoolYard;
