import { PROJECT_NAME, USER_ADDRESS, ADDRESS_KEY } from "../../constants";
import { connex } from "../../constants/contract"
import logo from '../../images/Naamloos.png';
import twitter from '../../images/1051382.png';
import insta from '../../images/1384089.png';
import discord from '../../images/3800060.png';
import menu from '../../images/168214.png';
import {NETWORK} from "../../constants";

const Header = () => {
    const handleLogIn = async () => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/";
            })
    };

    const handleLogout = () => {
        localStorage.removeItem(ADDRESS_KEY)
        document.location.href = "/";
    }

    const handleMouseEnter = e => {
        e.preventDefault();
        e.target.style.cursor = "pointer"
    }


    return <header className="u-clearfix u-header u-palette-5-light-2 u-header" id="sec-45ca">
        <div className="u-clearfix u-sheet u-sheet-1">
            <a href="/" data-page-id="215454347" className="u-image u-logo u-image-1" data-image-width="512" data-image-height="512" title="NoNerdsInc">
                <img src={logo} alt={"logo"} className="u-logo-image u-logo-image-1" />
            </a>
            <nav className="u-menu u-menu-dropdown u-offcanvas u-offcanvas-shift u-menu-1" data-position="" data-responsive-from="XL">
                <div className="menu-collapse" style={{ fontSize: '1rem', letterSpacing: '0px' }}>
                    <button className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-top-bottom-menu-spacing u-file-icon u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base u-file-icon-1" rel="noreferrer" href="#">
                        <img src={menu} alt="" />
                    </button>
                </div>
                <div className="u-custom-menu u-nav-container">
                    <ul className="u-nav u-unstyled u-nav-1">
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/" style={{ padding: '10px 20px' }}>NoNerdsInc</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/roadmap" style={{ padding: '10px 20px' }}>Roadmap</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/school-yard" style={{ padding: '10px 20px' }}>MINT A BULLY</a></li>
                        {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/cabinet" style={{ padding: '10px 20px' }}>Medicine Cabinet</a></li>}
                        {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/membership" style={{ padding: '10px 20px' }}>Membership Passes</a></li>}
                        {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/my-crew" style={{ padding: '10px 20px' }}>My Crew</a></li>}
                        {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/unionbank" style={{ padding: '10px 20px' }}>Union Bank System</a></li>}
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" rel="noreferrer" href="https://BlackVeMarket.com" target="_blank" style={{ padding: '10px 20px' }}>AfterMarket</a></li>
                        {USER_ADDRESS
                            ? <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{ padding: '20px 20px' }} onClick={handleLogout}>{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)} <br /> Logout</a></li>
                            : <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{ padding: '20px 20px' }} onClick={handleLogIn}>Connect your wallet</a></li>
                        }
                    </ul>
                </div>
                <div className="u-custom-menu u-nav-container-collapse">
                    <div className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                        <div className="u-inner-container-layout u-sidenav-overflow">
                            <div className="u-menu-close"></div>
                            <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/" style={{ padding: '10px 20px' }}>NoNerdsInc</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/roadmap" style={{ padding: '10px 20px' }}>Roadmap</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/school-yard" style={{ padding: '10px 20px' }}>MINT A BULLY</a></li>
                                {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/cabinet" style={{ padding: '10px 20px' }}>Medicine Cabinet</a></li>}
                                {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/membership" style={{ padding: '10px 20px' }}>Membership Passes</a></li>}
                                {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/my-crew" style={{ padding: '10px 20px' }}>My Crew</a></li>}
                                {USER_ADDRESS && <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/unionbank" style={{ padding: '10px 20px' }}>Union Bank System</a></li>}
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" rel="noreferrer" href="https://BlackVeMarket.com" target="_blank" style={{ padding: '10px 20px' }}>AfterMarket</a></li>
                                {USER_ADDRESS
                                    ? <li className="u-nav-item"><a className="u-button-style u-nav-link" style={{ padding: '20px 20px' }} onClick={handleLogout} onMouseEnter={handleMouseEnter}>{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)} <br /> Logout</a></li>
                                    : <li className="u-nav-item"><a className="u-button-style u-nav-link" style={{ padding: '20px 20px' }} onClick={handleLogIn} onMouseEnter={handleMouseEnter}>Connect your wallet</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
                </div>
            </nav>
            <div className="u-social-icons u-spacing-10 u-social-icons-1">
                <a className="u-social-url" title="twitter" rel="noreferrer" target="_blank" href="https://twitter.com/NoNerdsInc"><span className="u-file-icon u-icon u-social-icon u-social-twitter u-icon-1"><img src={twitter} alt="" /></span></a>
                <a className="u-social-url" title="instagram" rel="noreferrer" target="_blank" href="https://www.instagram.com/BlackVeMarket/"><span className="u-file-icon u-icon u-social-icon u-social-instagram u-icon-2"><img src={insta} alt="" /></span></a>
                <a className="u-social-url" target="_blank" rel="noreferrer" href="https://discord.gg/jv6awkeEAE" data-type="Custom" title="Custom"><span className="u-file-icon u-icon u-social-custom u-social-icon u-icon-3"><img src={discord} alt="" /></span></a>
            </div>
            {NETWORK === 'test' ? 'TESTNET' : null}
        </div>
    </header>
}

export default Header
